<!--Progressbar y estilo de: http://hilongjw.github.io/vue-progressbar/index.html-->
<template>
  <v-app
    :style="{
      'background-image': 'linear-gradient(120deg, #155799, #159957)',
      color: '#fff',
    }"
  >
    <vue-progress-bar></vue-progress-bar>
    <v-container fill-height>
      <v-layout align-center>
        <v-flex>
          <div v-if="paso == 0">
            <center>
              <!--
              <h1>🎁¿Buscas el regalo ideal?🎁</h1>
              <br>
              <h1>🎁</h1>
              <br>
              -->
              <v-layout row wrap>
                <v-flex xs1><h1>🎁</h1></v-flex>
                <!--
                <v-flex xs10>
                  <h1>
                    {{ actual }}
                    <p v-for="area of areas" :key="area.pasos">
                      {{ area }} - {{ area.pasos }}
                    </p>
                  </h1>
                </v-flex>
                -->
                <v-flex xs10><h1>¿Buscas el regalo ideal?</h1></v-flex>
                <!--
                <v-flex xs10>
                  <h1>¿Buscas el ideal? {{ id }}</h1>
                </v-flex>
                -->
                <v-flex xs1>
                  <h1>🎁</h1>
                </v-flex>
              </v-layout>
              <br />
              <br />
              <!--
                <h2>Responde el test y encuentra el perfecto para cada persona</h2>
              -->
              <h2>Responde el test y encuentra el perfecto</h2>
              <br />
              <v-btn color="success" @click="siguiente(0, '')">
                Comenzar ➡
              </v-btn>
            </center>
          </div>
          <div v-else-if="paso == 1">
            <center>
              <h1>¿Cuantos años tiene?</h1>
              <br />
              <v-btn @click="(edad = 'bebe'), siguiente(1, 'bebe')">
                Bebe 🍼
              </v-btn>
              <br />
              <br />
              <v-btn @click="(edad = 'nino'), siguiente(1, 'nino')">
                Niño ⚽
              </v-btn>
              <br />
              <br />
              <v-btn
                @click="(edad = 'adolescente'), siguiente(1, 'adolescente')"
              >
                Adolescente 💄
              </v-btn>
              <br />
              <br />
              <v-btn @click="(edad = 'joven'), siguiente(1, 'joven')">
                Joven 🚗
              </v-btn>
              <br />
              <br />
              <v-btn @click="(edad = 'adulto'), siguiente(1, 'adulto')">
                Adulto 🔨
              </v-btn>
              <br />
              <br />
              <v-btn @click="(edad = 'mayor'), siguiente(1, 'mayor')">
                Mayor 📢
              </v-btn>
            </center>
          </div>
          <div v-else-if="paso == 2">
            <center>
              <h1>¿Que es para ti?</h1>
              <br />
              <v-btn @click="(relacion = 'familia'), siguiente(2, 'familia')">
                Familia 👨‍👩‍👧‍👧
              </v-btn>
              <br />
              <br />
              <v-btn @click="(relacion = 'amigo'), siguiente(2, 'amigo')">
                Amigo 🎳
              </v-btn>
              <br />
              <br />
              <v-btn @click="(edad = 'pareja'), siguiente(2, 'pareja')">
                Pareja 👫
              </v-btn>
              <br />
              <br />
              <v-btn @click="(edad = 'companero'), siguiente(2, 'companero')">
                Compañero 💻
              </v-btn>
            </center>
          </div>
          <div v-else-if="paso == 3">
            <center>
              <h1>¿Es más de... ?</h1>
              <br />
              <v-btn @click="(af_playa = 'playa'), siguiente(3, 'playa')">
                Playa 🌴
              </v-btn>
              <br />
              <br />
              <v-btn @click="(af_playa = 'montana'), siguiente(3, 'montana')">
                Montaña 🏔
              </v-btn>
            </center>
          </div>
          <div v-else-if="paso == 4">
            <center>
              <h1>¿Es más de... ?</h1>
              <br />
              <v-btn @click="(af_peli = 'peli'), siguiente(4, 'peli')">
                Peli y manta 🍿
              </v-btn>
              <br />
              <br />
              <v-btn @click="(af_peli = 'terraza'), siguiente(4, 'terraza')">
                Terraza 🍾
              </v-btn>
            </center>
          </div>
          <!--
          <div v-else-if="paso == 5">
              <center>
                  <h1>¿Es más de... ?</h1>
                  <br>
                  <v-btn @click="af_peli='peli', siguiente()">Peli y manta 🍿</v-btn>
                  <br>
                  <v-btn @click="af_peli='terraza', siguiente()">Terraza 🍾</v-btn>
              </center>
          </div>
          -->
          <div v-else-if="paso == 5">
            <center>
              <h1>¿Cuanto quieres gastar?</h1>
              <br />
              <v-btn @click="(dinero = 'poco'), siguiente(5, 'poco')">
                0-20€ 💷
              </v-btn>
              <br />
              <br />
              <v-btn @click="(dinero = 'medio'), siguiente(5, 'medio')">
                20-50€ 💰
              </v-btn>
              <br />
              <br />
              <v-btn @click="(dinero = 'mucho'), siguiente(5, 'mucho')">
                50-100€ 💳
              </v-btn>
              <br />
              <br />
              <v-btn @click="(dinero = 'atope'), siguiente(5, 'atope')">
                +100€ 💳💳
              </v-btn>
            </center>
          </div>
          <div v-else-if="paso == 6">
            <center>
              <h1>¡ Buscando el regalo perfecto !</h1>
              <br />
              <br />
              <v-progress-circular
                :rotate="-90"
                :size="200"
                :width="20"
                :value="progreso_circular"
                color="white"
              >
                <h2>{{ progreso_circular }}</h2>
              </v-progress-circular>
              <br />
              <br />
              <br />
              <v-btn v-if="visibilidad_final" color="success" @click="final()">
                <h2>🎁 Abrir 🎁</h2>
              </v-btn>
            </center>
          </div>
        </v-flex>
      </v-layout>
    </v-container>
    <!--
    <v-footer padless v-if="paso == 0">
      <v-col class="text-center" cols="12">
        <strong>Contacto: contacto@novedad.net</strong>
        <br />
        Esta web participa en el Programa de Afiliados de Amazon EU, un programa
        de publicidad para afiliados diseñado para ofrecer a sitios web un modo
        de obtener comisiones por publicidad, publicitando e incluyendo enlaces
        a Amazon.es
      </v-col>
    </v-footer>
    -->
  </v-app>
</template>
<script>
import { StoreDB } from "@/services/fireinit.js";

export default {
  props: ["id"],
  data() {
    return {
      paso: 0,
      numero_total_de_pasos: 6,
      edad: "bebe",
      genero: "hombre",
      relacion: "familia",
      dinero: "poco",
      af_playa: "",
      af_peli: "",
      interval: {},
      progreso_circular: 0,
      visibilidad_final: false,

      areas: {},

      actual: "",
    };
  },
  created() {
    //this.actual = this.$route.params.id;
    //console.log(this.$route);
    //console.log(this.$router)
    StoreDB.collection("areas")
      .get()
      .then((querySnapshot) => {
        const documents = querySnapshot.docs.map((doc) => doc.data());
        //console.log(documents);
        this.areas = documents;
        // do something with documents
      });
  },

  mounted() {
    //console.log(this.$route.params.id);
    //this.$ga.page('/')
  },
  methods: {
    siguiente(n_paso, item) {
      //this.$gtag.event(n_paso.toString(),  item );
      this.$gtag.event(item, { event_category: n_paso.toString() });
      this.paso = this.paso + 1;

      var incremento = Math.ceil(100 / this.numero_total_de_pasos);
      if (this.paso == 1) {
        this.$Progress.set(incremento);
      } else if (this.paso == this.numero_total_de_pasos) {
        //Barra de progreso al maximo
        this.$Progress.set(100);

        //Progreso circular
        this.interval = setInterval(() => {
          if (this.progreso_circular >= 100) {
            this.visibilidad_final = true;
          } else {
            //this.progreso_circular += 10
            var a_sumar = Math.floor(Math.random() * 3 + 1) + 12;
            if (a_sumar + this.progreso_circular > 100) {
              this.progreso_circular = 100;
            } else {
              this.progreso_circular += a_sumar;
            }
          }
        }, 300);
      } else {
        this.$Progress.increase(incremento);
      }
    },
    final() {
      var link = "";

      //Variables intermedias
      var cercana = false;
      if (this.relacion == "familia" || this.relacion == "pareja") {
        cercana = true;
      }

      //Bebe --> Solo importa la relacion y cuanto gastar
      if (this.edad == "bebe") {
        if (this.dinero == "poco") {
          if (cercana) {
            //https://www.amazon.es/Precioso-Marco-Huella-Manos-Pies/dp/B01GJ9VNJC
            link = "https://amzn.to/2Rs7OcK"; //Huella y foto -17,97€
          } else {
            //https://www.amazon.es/Cohete-Botella-Rocket-Capacidad-estampado/dp/B06WW9RRF1/
            link = "https://amzn.to/2RtFu9C"; //Biberon cohete -17,11€ NO AMAZON
          }
        } else if (this.dinero == "medio") {
          if (cercana) {
            //https://www.amazon.es/Dotty-Fish-Infancia-Primeros-wildledersohle/dp/B07K6VDC59/
            link = "https://amzn.to/2CSCYV9"; //Zapgo bebe - 20,49€
          } else {
            //https://www.amazon.es/Fisher-Price-Perrito-Primeros-descubrimientos-FPM53/dp/B079MNMWF7/
            link = "https://amzn.to/35k3eEj"; //Peluche - 27,99€
          }
        } else if (this.dinero == "mucho") {
          //https://www.amazon.es/Trona-madera-Safety-1st-Natural/dp/B00NRSTEZO/
          link = "https://amzn.to/2BcXyQ4"; //Silla para comer - 72€
        } else {
          //https://www.amazon.es/BabyBj%C3%B6rn-Hamaca-Bliss-Antracita-Algod%C3%B3n/dp/B01N4F0A8P/
          link = "https://amzn.to/2DIxs9g"; //Hamaca para bebes - 133€
        }
      }
      //Nino
      else if (this.edad == "nino") {
        if (this.dinero == "poco") {
          //https://www.amazon.es/Play-Doh-La-Tostadora-Hasbro-E0039EU4/dp/B07B6KYPDJ/
          link = "https://amzn.to/3qWouMs"; //Juego plastilina -11,99€
        } else if (this.dinero == "medio") {
          //https://www.amazon.es/UNO-Attack-Card-Game-Mattel/dp/B0042ET8S0/
          link = "https://amzn.to/3faZI5e"; //Uno attack -43,59€
        } else if (this.dinero == "mucho") {
          //https://www.amazon.es/LEGO%C2%AE-tbd-Extra-2019-Technic-Juego-construcci%C3%B3n/dp/B07P2GQDQ6/
          link = "https://amzn.to/3qYkKK4"; //LEGO Technic -133,34€
        } else {
          //https://www.amazon.es/Scalextric-Twister-Competition-Xtreme-C10260S500/dp/B07F7ZZSXD/
          link = "https://amzn.to/2yg1K3e"; //Scalextric -87,99€
        }
      }
      //Adolescente
      else if (this.edad == "adolescente") {
        if (this.dinero == "poco") {
          //https://www.amazon.es/Idena-186535-P-Dardos/dp/B00IS8RSV0/
          link = "https://amzn.to/2SoCxKM"; //Diana dardos -12,75€
        } else if (this.dinero == "medio") {
          //https://www.amazon.es/Hasbro-Gaming-Trivial-Pursuit-C1940105/dp/B07233GYMT/
          link = "https://amzn.to/2BeCTex"; //Trivial Pursuit - 32,45€
        } else if (this.dinero == "mucho") {
          //https://www.amazon.es/EACHINE-Camaras-Profesional-Distancia-Plegable/dp/B07VC2SPR1/
          link = "https://amzn.to/2WbMBrO"; //Drone - 160€
        } else {
          //https://www.amazon.es/AMAZFIT-Smartwatch-Inteligente-Internacional-Funciona/dp/B07GV9XL58/
          link = "https://amzn.to/2WgoyYJ"; //Amazfit Stratos - 180€
        }
      }
      //Joven
      else if (this.edad == "joven") {
        if (this.dinero == "poco") {
          //https://www.amazon.es/Escapades-edici%C3%B3n-original-banderas-viajeros/dp/B07DYW7Y8V/
          link = "https://amzn.to/2BeFfdn"; //Mapa mundi rascar -13€
        } else if (this.dinero == "medio") {
          //https://www.amazon.es/dp/B079NL1GMZ/
          link = "https://amzn.to/3p3BRsv"; //100 things to do - 25€
        } else if (this.dinero == "mucho") {
          //https://www.amazon.es/dp/B085PM3PTZ/
          link = "https://amzn.to/2IO0Lwx"; //Polaroid -100€
        } else {
          //https://www.amazon.es/GoPro-Hero6-Black-Videoc%C3%A1mara-Resistente/dp/B074X5WPC5/
          link = "https://amzn.to/2UsjsGs"; //GoPro - 254€
        }
      }
      //Adulto
      else if (this.edad == "adulto") {
        if (this.dinero == "poco") {
          //https://www.amazon.es/Taza-para-enamorados-San-Valent%C3%ADn/dp/B06Y28PWVQ/
          link = "https://amzn.to/35nARqg"; //Taza - 11€
        } else if (this.dinero == "medio") {
          //https://www.amazon.es/AY-Impermeable-Tecnolog%C3%ADa-Resistente-Construido/dp/B07FDQB5P5/
          link = "https://amzn.to/2VRCTvH"; //Altavoz - 41€
        } else if (this.dinero == "mucho") {
          //https://www.amazon.es/Smartwatch-Monitore-Puls%C3%B3metro-Ejercicio-Internacional/dp/B07F8WBD5Y/
          link = "https://amzn.to/2UrAbtm"; //Amazfit bip - 75€
        } else {
          //https://www.amazon.es/Xiaomi-Mi-Scooter-Patinete-el%C3%A9ctrico/dp/B077QQ1DMT/
          link = "https://amzn.to/2Rucn6e"; //Patinete - 370€
        }
      }
      //Mayor
      else {
        if (this.dinero == "poco") {
          //https://www.amazon.es/Ravensburger-Puzzle-Piezas-Paisajes-14976/dp/B07Z1D4VDL/
          link = "https://amzn.to/2WlCVee"; //Puzzle -23€
        } else if (this.dinero == "medio") {
          //https://www.amazon.es/Ecomed-23002-ECOMED-COJ%C3%8DN/dp/B00PS9GIZE/
          link = "https://amzn.to/2SpQc4w"; //Manta electrica
        } else if (this.dinero == "mucho") {
          //https://www.amazon.es/dp/B01BB16DX4/
          link = "https://amzn.to/3f64ktk"; //Haz tu cerveza - 54€
        } else {
          //https://www.amazon.es/Tommy-Hilfiger-1791397-Reloj-Hombre/dp/B075H3JWMG/
          link = "https://amzn.to/2UrCdtu"; //Reloj caro - 190€
        }
      }
      location.href = link;
    },
  },
  name: "App",
};
</script>

<style scoped>
h1 {
  font-size: 32px;
}
h2 {
  font-size: 24px;
}
button {
  font-size: 18px;
}
</style>
