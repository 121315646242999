import Vue from "vue";
import VueRouter from "vue-router";
import Home from "../views/Home.vue";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "home",
    component: Home
  },
  {
    path: "/about",
    name: "about",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/About.vue")
  },
  {
    path: "/altavoces",
    name: "altavoces",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/Altavoces.vue")
  },
  {
    path: "/patinetes",
    name: "patinetes",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/Patinetes.vue")
  },
  {
    path: "/moviles",
    name: "moviles",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/Moviles.vue")
  },
  {
    path: "/ejercicio",
    name: "ejercicio",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/Ejercicio.vue")
  },
  {
    path: "/sheepvan",
    name: "sheepvan",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/SheepVan.vue")
  },
  {
    path: "/redirect1",
    name: "redirect1",
    //component: () => window.location.href = 'https://amzn.to/39YnOzN'
    //component: () => window.location.href = 'https://www.roblox.com/catalog/5560178482/Kepulin-De-Coraz-n'
    component: () =>
      (window.location.href =
        "https://youtube.com/shorts/xhJl2eDSW-s?feature=share")
  },
  {
    path: "/redirect2",
    name: "redirect2",
    //component: () => window.location.href = 'https://amzn.to/3410UUN'
    //component: () => window.location.href = 'https://www.roblox.com/catalog/5107480299/MORDISQUIN-by-KepuTheCat'
    component: () =>
      (window.location.href =
        "https://youtube.com/shorts/xhJl2eDSW-s?feature=share")
  },
  {
    path: "/redirect3",
    name: "redirect3",
    //component: () => window.location.href = 'https://novedad.net/'
    component: () =>
      (window.location.href =
        "https://youtube.com/shorts/xhJl2eDSW-s?feature=share")
  },
  {
    path: "/per",
    name: "per",
    //component: () => window.location.href = 'https://novedad.net/'
    component: () =>
      (window.location.href =
        "https://youtube.com/shorts/xhJl2eDSW-s?feature=share")
  },
  {
    path: "/ins",
    name: "ins",
    //component: () => window.location.href = 'https://novedad.net/'
    component: () =>
      (window.location.href =
        "https://youtube.com/shorts/xhJl2eDSW-s?feature=share")
  },
  {
    path: "/enLittle",
    name: "enLittle",
    //component: () => window.location.href = 'https://novedad.net/'
    component: () =>
      (window.location.href =
        "https://youtube.com/shorts/xhJl2eDSW-s?feature=share")
  },
  {
    path: "/enBody",
    name: "enBody",
    //component: () => window.location.href = 'https://www.youtube.com/channel/UC97k3hlbE-1rVN8y56zyEEA?sub_confirmation=1'
    component: () =>
      (window.location.href =
        "https://youtube.com/shorts/xhJl2eDSW-s?feature=share")
  },
  {
    path: "/enNug",
    name: "enNug",
    //component: () => window.location.href = 'https://storyfire.com/'
    component: () =>
      (window.location.href =
        "https://youtube.com/shorts/xhJl2eDSW-s?feature=share")
  },
  {
    path: "/enJay",
    name: "enJay",
    //component: () => window.location.href = 'https://www.youtube.com/channel/UCbV60AGIHKz2xIGvbk0LLvg?sub_confirmation=1'
    component: () =>
      (window.location.href =
        "https://youtube.com/shorts/xhJl2eDSW-s?feature=share")
  },
  {
    path: "/enEdd",
    name: "enEdd",
    //component: () => window.location.href = 'https://www.youtube.com/channel/UCTEpWW26-LKVvIP9mZkvlRw'
    component: () =>
      (window.location.href =
        "https://youtube.com/shorts/xhJl2eDSW-s?feature=share")
  },
  {
    path: "/enSgv",
    name: "enSgv",
    //component: () => window.location.href = 'https://www.youtube.com/c/KannadaFullMovies/videos'
    component: () =>
      (window.location.href =
        "https://youtube.com/shorts/xhJl2eDSW-s?feature=share")
  },
  {
    path: "/enNew",
    name: "enNew",
    //component: () => window.location.href = 'https://www.youtube.com/channel/UC5mMf2NE17_MiTL94ihRN2w'
    component: () =>
      (window.location.href =
        "https://youtube.com/shorts/xhJl2eDSW-s?feature=share")
  }
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes
});

export default router;
