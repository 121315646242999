import * as firebase from "firebase/app";
import "firebase/auth";
import "firebase/firestore";
import "firebase/database";
import "firebase/messaging";

var config = {
  apiKey: "AIzaSyBmFzV7bypX_Su2PYHQCoX8z2h59yGvXlE",
  authDomain: "regaloregalo-76d1d.firebaseapp.com",
  databaseURL: "https://regaloregalo-76d1d.firebaseio.com",
  projectId: "regaloregalo-76d1d",
  storageBucket: "regaloregalo-76d1d.appspot.com",
  messagingSenderId: "873548818487",
  appId: "1:873548818487:web:b0ba53ae229dbe217277b2",
  measurementId: "G-89YVQLVE87"
};

!firebase.apps.length ? firebase.initializeApp(config) : "";
export const GoogleProvider = new firebase.auth.GoogleAuthProvider();
export const auth = firebase.auth();
export const DB = firebase.database();
export const StoreDB = firebase.firestore();
export const Messaging = firebase.messaging();
export default firebase;
