import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import vuetify from "./plugins/vuetify";
import VueProgressBar from "vue-progressbar";
import VueGtag from "vue-gtag";
//Add firebase messaging
//import firebaseMessaging from './firebase'

Vue.config.productionTip = true;

/* VUE ANALYTICS */
Vue.use(VueGtag, {
  config: { id: "UA-133505611-1" }
});

/* FIREBASE MESSAGING */
/*
Vue.prototype.$messaging = firebaseMessaging
*/

/* FIN VUE ANALYTICS*/

/* VUE PROGRESSBAR */
const options = {
  color: "#bffaf3",
  failedColor: "#874b4b",
  thickness: "5px",
  transition: {
    speed: "0.2s",
    opacity: "0.6s",
    termination: 300
  },
  autoRevert: true,
  location: "top",
  inverse: false
};
Vue.use(VueProgressBar, options);
/*FIN VUE PROGRESSBAR */

new Vue({
  router,
  vuetify,
  render: h => h(App)
}).$mount("#app");
